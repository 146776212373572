import { fetcher } from "@/utils/api"
import { useQuery } from "@tanstack/react-query"
import { NotificationScope } from "../types/notificationScope"

export const useUnreadNotificationsCount = (scope: NotificationScope) => {
  const defaultData = {
    total: 0,
    candidates: 0,
    tasks: 0,
  }
  const { data: count } = useQuery<{
    total: number
    candidates: number
    tasks: number
  }>({
    queryKey: ["notifications", "unread", "count", { scope }],
    queryFn: async () =>
      fetcher(`/notifications/v2/unread/count?scope=${scope}`),
    placeholderData: defaultData,
  })

  return count ?? defaultData
}
